import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { ViewTable, execConfirmModal, Button, ButtonAdd, ButtonDelete } from 'shared';
import { useListTagsStyles } from './listTags.styles';
import { useTagsBuildTable } from './useTagsBuildTable';
import { useTagsPaginated } from './useTagsPaginated';
import { FormTagRange } from './FormTagRange';
import { FormCsvFileUpload } from './FormCsvFileUpload';
import { UserContext, ROLES } from 'auth';
import { FormExport } from './FormExport/FormExport';

export function ListTags() {
  const classes = useListTagsStyles();
  const { t } = useTranslation(NAME_SPACE.TAGS);
  const $formCreateTagRange = useRef(null);
  const $formFileUploadRef = useRef(null);
  const $formExportRef = useRef(null);
  const { user } = useContext(UserContext);
  const [exporting, setExporting] = useState(false);

  const { tags, deleteTags, addTagRange, importTags, exportTags, getTags, errorInfo, setErrorInfo, errorInfoList } =
    useTagsPaginated();
  const { rows, headCells } = useTagsBuildTable(tags, t);

  const {
    isOpen: isOpenCreateRangeModal,
    handleClose: handleCloseCreateRangeModal,
    handleOpen: handleOpenCreateRangeModal
  } = useHandleOpen(false);

  const { isOpen: isOpenImport, handleClose: handleCloseImport, handleOpen: handleOpenImport } = useHandleOpen(false);

  const { isOpen: isOpenExport, handleClose: handleCloseExport, handleOpen: handleOpenExport } = useHandleOpen(false);

  function handleCreateRange() {
    setErrorInfo();
    handleOpenCreateRangeModal();
  }

  function handleModalAcceptCreateTagRange() {
    $formCreateTagRange.current.submit();
  }

  async function handleCreateTagRangeSubmit(tagRange) {
    const success = await addTagRange(tagRange);
    if (success) {
      handleCloseCreateRangeModal();
    }
  }

  function handleImport() {
    setErrorInfo();
    handleOpenImport();
  }

  async function handleImportSubmit(importParams) {
    if (importParams?.selectedFile) {
      const success = await importTags(importParams);
      if (success) {
        handleCloseImport();
      }
    }
  }

  function handleModalAcceptImport() {
    $formFileUploadRef.current.submit();
  }

  async function handleDelete(tagsToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description:
          tagsToDelete.length > 1
            ? t('removeConfirm.message', { length: tagsToDelete.length })
            : t('removeConfirm.singularMessage', {
                userName: tagsToDelete[0].name
              }),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });
      await deleteTags(tagsToDelete);
    } catch (err) {}
  }

  async function handleExport() {
    setErrorInfo();
    handleOpenExport();
  }

  function handleModalAcceptExport() {
    $formExportRef.current.submit();
  }

  async function handleExportSubmit(exportParameters) {
    setExporting(true);
    const success = await exportTags(exportParameters);
    if (success) {
      handleCloseExport();
    }
    setExporting(false);
  }

  function composeActionButtons() {
    if (user?.role !== ROLES.TAG_MANAGER) {
      return [];
    }

    const actionButtons = [
      {
        condition: isAnySelected => isAnySelected,
        resetOnCB: true,
        cb: handleDelete,
        component: ButtonDelete
      },
      {
        condition: isAnySelected => !isAnySelected,
        properties: ['id'],
        cb: handleCreateRange,
        component: ButtonAdd
      },
      {
        condition: isAnySelected => !isAnySelected,
        title: t('actions.export'),
        name: 'export',
        properties: ['id'],
        label: 'export tags',
        cb: handleExport,
        icon: <GetAppIcon />,
        component: Button
      },
      {
        condition: isAnySelected => !isAnySelected,
        title: t('actions.import'),
        name: 'import',
        properties: ['id'],
        label: 'import tags',
        cb: handleImport,
        icon: <PublishIcon />,
        component: Button
      }
    ];
    return actionButtons;
  }

  return (
    <>
      <div className={classes.tags}>
        <ViewTable
          elevation
          spaced
          errors={errorInfoList}
          rows={rows}
          emptyText={t('empty')}
          cells={headCells}
          mainKey={'uuid'}
          onFetchData={getTags}
          totalRows={tags.total}
          serverSidePaging
          selectRowWhen={element => user?.role === ROLES.TAG_MANAGER && element.canBeDeleted}
          toolbar={{
            title: t('name'),
            actions: composeActionButtons()
          }}
        />
      </div>

      <DialogModal
        maxWidth="md"
        className={classes.modal}
        title={t('createRange.dialogTitle')}
        description=""
        isOpen={isOpenCreateRangeModal}
        onClose={handleCloseCreateRangeModal}
        buttons={[
          {
            children: <KeyboardArrowLeft />,
            text: t('common:actions.back'),
            type: 'secondary',
            onClick: handleCloseCreateRangeModal,
            className: classes.buttonDelete
          },
          {
            children: <SaveAltIcon />,
            text: t('common:actions.save'),
            type: 'primary',
            onClick: handleModalAcceptCreateTagRange,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormTagRange formRef={$formCreateTagRange} onSubmit={handleCreateTagRangeSubmit} errors={errorInfo} />
      </DialogModal>

      <DialogModal
        title={t('import.dialogTitle')}
        description=""
        fullWidth
        isOpen={isOpenImport}
        onClose={handleCloseImport}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleCloseImport,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.add'),
            type: 'primary',
            onClick: handleModalAcceptImport,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormCsvFileUpload
          formRef={$formFileUploadRef}
          onSubmit={handleImportSubmit}
          accept="text/csv"
          maxFiles={1}
          errors={errorInfo}
        />
      </DialogModal>

      <DialogModal
        maxWidth="xs"
        className={classes.modal}
        title={t('export.dialogTitle')}
        description=""
        isOpen={isOpenExport}
        onClose={handleCloseExport}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.no'),
            type: 'secondary',
            onClick: handleCloseExport,
            disabled: exporting,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.yes'),
            type: 'primary',
            onClick: handleModalAcceptExport,
            disabled: exporting,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormExport formRef={$formExportRef} onSubmit={handleExportSubmit} errors={errorInfo} />
      </DialogModal>
    </>
  );
}
