import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { NAME_SPACE } from 'i18n';
import { AsyncInputAutocomplete, Form, Input, Switch } from '@engloba-tech/englobity';
import { experiences } from 'services';
import { useFormTagRangeStyles } from './formTagRange.styles';
import { multilanguageExperiences } from 'services';
import { execConfirmModal } from 'shared';

export function FormTagRange({ formRef, onSubmit, errors }) {
  const { t } = useTranslation(NAME_SPACE.TAGS);
  const classes = useFormTagRangeStyles();
  const [inputs, setInputs] = useState({});
  const [multilanguageExperience, setMultilanguageExperience] = useState(false);

  async function confirmTamperMismatch() {
    try {
      await execConfirmModal({
        title: `⚠️ ${t('createRange.tamperMismatchWarningModal.title')}`,
        description: t('createRange.tamperMismatchWarningModal.description'),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel')
      });

      return true;
    } catch {
      return false;
    }
  }

  async function handleSubmit() {
    if (!inputs.productId) return;

    if (!inputs.tamper && inputs.productIsTamperType && !(await confirmTamperMismatch())) {
      return;
    }

    onSubmit(inputs);
  }

  async function handleExperienceAutocomplete(element) {
    try {
      setInputs(prevFilters => ({
        ...prevFilters,
        productId: element?.id,
        product: element?.name,
        productIsTamperType: element?.isTamperType
      }));
    } catch {}
  }

  function handleMultiLanguageSwitchChange() {
    setMultilanguageExperience(prev => !prev);
    setInputs(prevFilters => ({
      ...prevFilters,
      productId: '',
      product: '',
      productIsTamperType: false
    }));
  }

  const handleChange =
    fieldName =>
    ({ target }) =>
      setInputs(prevInputs => ({ ...prevInputs, [fieldName]: target.value }));

  const handleSwitchChange =
    fieldName =>
    ({ target }) => {
      setInputs(inputs => ({ ...inputs, [fieldName]: !inputs[fieldName] }));
    };

  const handleGenerateSerialNumberChange = () => {
    setInputs(inputs => ({
      ...inputs,
      generateSerialNumber: !inputs.generateSerialNumber,
      initialLetter: !inputs.generateSerialNumber ? inputs.initialLetter : '',
      initialSerialNumber: !inputs.generateSerialNumber ? inputs.initialSerialNumber : ''
    }));
  };

  return (
    <Form className={classes.form} elementRef={formRef} onSubmit={handleSubmit} autoComplete="off" errors={errors}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="div" variant="body2">
            {t('createRange.description')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Tooltip title={t('checkMultilanguageTooltip')} placement={'bottom'}>
            <div>
              <Switch
                label={multilanguageExperience ? t('checkMultilanguage') : t('checkSingle')}
                checked={multilanguageExperience || false}
                onChange={handleMultiLanguageSwitchChange}
              />
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Tooltip title={t('createRange.checkTamperTooltip')} placement={'bottom'}>
            <div>
              <Switch
                label={t('createRange.properties.tamper')}
                checked={inputs.tamper || false}
                onChange={handleSwitchChange('tamper')}
              />
            </div>
          </Tooltip>
        </Grid>

        <Grid item xs={12} md={8}>
          <AsyncInputAutocomplete
            onChange={(e, element) => handleExperienceAutocomplete(element)}
            label={t('createRange.properties.product')}
            required
            variant="outlined"
            icon={<SearchIcon />}
            value={inputs.productId ? { name: inputs.product, id: inputs.productId } : null}
            defaultInputValue={inputs.product || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name}
            requestAction={
              multilanguageExperience ? multilanguageExperiences.getUsingQueryFilter : experiences.getUsingQueryFilter
            }
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            required
            name={t('createRange.properties.amount')}
            label={t('createRange.properties.amount')}
            variant="outlined"
            type="number"
            value={inputs.amount || ''}
            onChange={handleChange('amount')}
            inputProps={{ 'aria-label': t('createRange.properties.amount') }}
            validators={['required', 'minNumber:1']}
            errorMessages={[t('validations.required'), t('validations.minNumber', { minimum: 1 })]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Switch
            label={t('createRange.properties.generateSerialNumber')}
            checked={inputs.generateSerialNumber || false}
            onChange={handleGenerateSerialNumberChange}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Input
            required={inputs.generateSerialNumber}
            name={t('createRange.properties.initialLetter')}
            label={t('createRange.properties.initialLetter')}
            variant="outlined"
            value={inputs.initialLetter || ''}
            onChange={handleChange('initialLetter')}
            disabled={!inputs.generateSerialNumber}
            inputProps={{ 'aria-label': t('createRange.properties.initialLetter') }}
            validators={
              inputs.generateSerialNumber && ['required', 'maxStringLength:1', 'matchRegexp:^(?:[A-Za-z]{1}$)']
            }
            errorMessages={
              inputs.generateSerialNumber && [
                t('common:validations.required'),
                t('common:validations.maxStringLength', { length: 1 }),
                t('common:validations.invalidLetter')
              ]
            }
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Input
            name={t('createRange.properties.initialSerialNumber')}
            label={t('createRange.properties.initialSerialNumber')}
            variant="outlined"
            type="number"
            value={inputs.initialSerialNumber || ''}
            onChange={handleChange('initialSerialNumber')}
            disabled={!inputs.generateSerialNumber}
            inputProps={{ 'aria-label': t('createRange.properties.initialSerialNumber') }}
            validators={['minNumber:1', 'maxNumber:99998']}
            errorMessages={[t('validations.minNumber', { minimum: 1 }), t('validations.maxNumber', { maximum: 99998 })]}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name={t('createRange.properties.orderId')}
            label={t('createRange.properties.orderId')}
            variant="outlined"
            value={inputs.orderId || ''}
            onChange={handleChange('orderId')}
            inputProps={{ 'aria-label': t('createRange.properties.orderId') }}
            validators={[
              'matchRegexp:^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-8][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|[0-9A-F]{8}-[0-9A-F]{4}-[1-8][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}|00000000-0000-0000-0000-000000000000|ffffffff-ffff-ffff-ffff-ffffffffffff)$'
            ]}
            errorMessages={[t('validations.invalidUUID')]}
          />
        </Grid>
        <Grid item xs={12}>
          <Switch
            label={t('createRange.properties.exportCSV')}
            checked={inputs.exportCSV || false}
            onChange={handleSwitchChange('exportCSV')}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

FormTagRange.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object
};
